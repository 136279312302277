import { useState } from 'react';
import {
  Button,
  Typography,
  Stack,
  styled as styledMUI,
  Divider
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import './style.css';
import { CURRENCY } from 'configs/constants';

import PlanFeaturesList from 'components/PlanFeaturesList';
import { TSubscriptionType, TPlanList } from 'typings';

const CarouselContainer = styledMUI('div')(() => ({
  // height: '60vh',
  width: '100%',
  margin: '0 auto',
  position: 'relative',
  perspective: '1000px',
  transformStyle: 'preserve-3d',

  '& [type=radio]': {
    visibility: 'hidden'
  },

  '& label': {
    '& .Mui-Card': {
      height: '100%'
    }
  }
  //   '& input:checked + label': {
  //     transform: 'translate3d(0, 0, 0)'
  //   }
}));
const StyledLabel = styledMUI('label')(() => ({
  margin: 'auto',
  width: '72%',
  // height: '100%',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '40px',
  cursor: 'pointer',
  transition: 'transform 0.4s ease',

  borderRadius: '30px',
  backgroundColor: '#EDEDED',
  // position: 'relative',
  padding: '20px',
  // boxSizing: 'border-box',
  // boxShadow: 'none',
  // marginBottom: '20px',
  borderLeft: 'linear-gradient(45deg, black, transparent)'
  // '&::before': {
  //   backgroundImage: 'linear-gradient(45deg, black, transparent)',
  //   borderRadius: '20px',
  //   padding: '1px',
  //   width: '100%',
  //   height: '100%',
  //   top: '-1px',
  //   left: '-1px',
  //   zIndex: '-1',
  //   position: 'absolute',
  //   content: '""'
  // },
}));

// interface MyInputHTMLAttributes extends InputHTMLAttributes {
//     position: number
// }
// const StyledInput = styled('input')<MyInputHTMLAttributes>(({position, theme }) => ({
//     '& + label': {
//         transform: `translate3d(${position*50}%, 0, -100px)`
//     }
// }));

type TProps__PlansCarousel = {
  plansData: TPlanList[];
  currentPlan?: TSubscriptionType;
  allowSelection?: boolean;
  onPlanSelection?: (plan: TPlanList) => void;
  filterByLevel?: (plan: TPlanList) => number | boolean;
};

function PlansCarousel(props: TProps__PlansCarousel) {
  const {
    plansData,
    currentPlan,
    allowSelection = false,
    onPlanSelection,
    filterByLevel
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  // for carousel buttons
  // const checkNext = () => {
  //   const labels = document.querySelectorAll('#slider label');
  //   const nextIndex =
  //     selectedIndex === labels.length - 1 ? 0 : selectedIndex + 1;
  //   setSelectedIndex(nextIndex);
  // };

  const check = (index: number) => setSelectedIndex(index);

  console.log('plansData -- ', plansData);

  return (
    <CarouselContainer id="slider">
      {plansData.map((item, index) => (
        <input
          type="radio"
          name="slider"
          key={item.id}
          id={`s${index + 1}`}
          checked={selectedIndex === index}
          //   position={index - selectedIndex}
        />
      ))}
      {plansData.map((plan, index) => (
        <StyledLabel
          htmlFor={`s${index + 1}`}
          id={`slide${index + 1}`}
          key={plan.id}
          onClick={() => check(index)}
        >
          {plan.name === currentPlan?.sku_name &&
            plan.id === currentPlan?.sku_id && (
              <Typography
                variant={`${isMobile ? 'h6' : 'h3'}`}
                color="primary"
                align="center"
                sx={{
                  textTransform: 'uppercase',
                  marginBottom: 4,
                  marginTop: isMobile ? -6.25 : -5.25
                }}
              >
                Your current plan
              </Typography>
            )}
          {plan.name === 'Multi-Standard' &&
            plan.subscription_cycle === 12 &&
            plan.id !== currentPlan?.sku_id && (
              <Typography
                variant="h3"
                color="primary"
                align="center"
                sx={{
                  textTransform: 'uppercase',
                  marginBottom: 4,
                  marginTop: -5.25
                }}
              >
                Most popular
              </Typography>
            )}

          <Stack justifyContent="center" alignItems="center" mt={1.5}>
            <Typography variant="h3">{plan.name}</Typography>
            <Typography variant="h1">{`${CURRENCY[plan.prices[0].currency]}${(
              plan.prices[0].price / 100
            ).toFixed(2)}`}</Typography>
            {plan.prices[0]['price'] < plan.prices[0]['original_price'] && (
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h5"
                  sx={{ textDecoration: 'line-through', marginRight: 1 }}
                >{`${CURRENCY[plan.prices[0].currency]}${(
                  plan.prices[0].original_price / 100
                ).toFixed(2)}`}</Typography>
                <Typography variant="Body1SemiBold" color="secondary">{` save ${
                  CURRENCY[plan.prices[0].currency]
                }${
                  (plan.prices[0].original_price - plan.prices[0].price) / 100
                }`}</Typography>
              </Stack>
            )}
          </Stack>
          <Divider sx={{ margin: '16px auto' }} />
          <PlanFeaturesList featuresList={plan.supported_features} />
          {allowSelection && (
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (onPlanSelection) {
                  onPlanSelection(plan);
                }
              }}
              disabled={filterByLevel && !filterByLevel(plan)}
              sx={{ marginTop: '20px' }}
            >
              Select
            </Button>
          )}
        </StyledLabel>
      ))}
    </CarouselContainer>
  );
}

export default PlansCarousel;
