import styled from 'styled-components';
import CameraSnapshot from 'assets/camera-snapshot-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { IsMobile } from 'hooks/isMobile';
import GreenCheck from 'assets/check-circle-green.png';
import { Button, Typography } from '@mui/material';
import { Flex } from 'styles';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${IsMobile() ? '100%' : '50%'};
  padding: ${IsMobile() ? '0px' : '20px'};
  background-color: ${IsMobile() ? '#fff' : '#fcfcfc'};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: ${IsMobile()
    ? 'none'
    : `
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    `};
`;

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

const OfferContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #d7eea1;
  margin: 24px 0;
`;

const ExclusiveOffer = styled.div`
  display: flex;
  padding: 5px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
`;

interface IProps {
  isEligibleForTrial: boolean;
  deviceId: string;
}

export default function NoSubscription(props: IProps) {
  const navigate = useNavigate();
  const { isEligibleForTrial, deviceId } = props;

  return (
    <Flex justify="center">
      <Card>
        <Image src={CameraSnapshot} borderRadius="15" />
        <Typography>
          Unlock premium features for a more powerful camera!
        </Typography>
        {isEligibleForTrial && (
          <>
            <Typography>
              Choose the plan that fits your need: You have a 15 days free trial
              available
            </Typography>
            <OfferContainer>
              <ExclusiveOffer
                onClick={() =>
                  navigate('/buySubscription', {
                    state: {
                      isFreeTrial: isEligibleForTrial,
                      isUpgrade: false,
                      deviceId
                    }
                  })
                }
              >
                Exclusive Offer
              </ExclusiveOffer>
              <Typography>Try 15 days Free-Trial</Typography>
            </OfferContainer>
          </>
        )}

        <Flex justify="space-between" mbottom="10">
          <Flex mbottom="10">
            <Image src={GreenCheck} width="24" height="24" />
            <Typography>Save all your videos</Typography>
          </Flex>
          <Flex mbottom="10">
            <Image src={GreenCheck} width="24" height="24" />
            <Typography>Video recording history</Typography>
          </Flex>
          <Flex mbottom="10">
            <Image src={GreenCheck} width="24" height="24" />
            <Typography>Smart AI Detections</Typography>
          </Flex>
        </Flex>
        {isEligibleForTrial ? (
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{
              width: '100%'
            }}
            onClick={() =>
              navigate('/buySubscription', {
                state: {
                  isFreeTrial: isEligibleForTrial,
                  isUpgrade: false,
                  deviceId
                }
              })
            }
          >
            Continue With Free-Trial
          </Button>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{
              width: '100%'
            }}
            onClick={() =>
              navigate('/buySubscription', {
                state: {
                  isFreeTrial: isEligibleForTrial,
                  isUpgrade: false,
                  deviceId
                }
              })
            }
          >
            Buy
          </Button>
        )}
      </Card>
    </Flex>
  );
}
