/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */

import {
  Button,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup
} from '@mui/material';
import { Image } from 'styles';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TTransaction,
  TSubscriptionType,
  TPaymentMethods,
  TSpaceResponseData,
  SpaceRole
} from 'typings';
import axios from 'axios';
import dayjs from 'dayjs';
import { endpoints } from 'services/api/endpoints';
import Invoice from 'assets/invoice.png';
import { useAppSelector } from 'store/hooks';
import VisaCard from 'assets/visa.png';
import MasterCard from 'assets/mastercard.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InstaCard from 'components/InstaCard';
import { GetPaymentMethods } from 'services/api/paymentsApi';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';
import { GetAllSpaces } from 'services/api/SpacesApi';
import InstaSpinner from 'components/InstaSpinner';
import { SUBSCRIPTION_TYPE } from '../../typings/purchaseTypes';

function billingAndInvoiceSummary() {
  const navigate = useNavigate();

  const [transactionList, setTransactionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [paymentOption, setPaymentOption] = useState<TPaymentMethods[]>([]);
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
  const [isTransactionsFetched, setIsTransactionsFetched] = useState(false);
  const [loadErr, setLoadErr] = useState('');
  const [page, setPage] = useState(1);
  const [selectedCreditCard, setSelectedCreditCard] = useState('');
  const pageSize = 5;
  const [spaceData, setSpaceData] = useState<TSpaceResponseData[]>([]);
  const [subscriptionData, setSubscriptionList] = useState<TSubscriptionType[]>(
    []
  );
  const fetchSavedCards = () => {
    setIsPaymentLoading(true);

    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
      })
      .catch((err) => console.log('err payment_methods', err))
      .finally(() => {
        setIsPaymentLoading(false);
      });
  };

  const addNewPaymentMethod = () => {
    navigate('/billings/new');
  };

  useEffect(() => {
    setIsLoading(
      isPaymentLoading || isSubscriptionLoading || isTransactionsLoading
    );
  }, [isPaymentLoading, isSubscriptionLoading, isTransactionsLoading]);

  useEffect(() => {
    setIsSubscriptionLoading(true);
    Promise.all([getSubscriptionDetails(), GetAllSpaces()])
      .then((res) => {
        const [subscriptionsData, allSpacesResponse] = res;
        console.log('subscriptionsData---', subscriptionsData);
        const ownerSpace = allSpacesResponse.data.filter((item) => {
          return item.role === SpaceRole.OWNER;
        });
        setSubscriptionList(subscriptionsData.data);

        setSpaceData(ownerSpace);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsSubscriptionLoading(false);
      });
  }, []);

  const LoadMoreDetails = useCallback(() => {
    setIsTransactionsLoading(true);
    setIsTransactionsFetched(false);

    axios
      .get(endpoints.getTransactionsUrl((page - 1) * pageSize))
      .then((res) => {
        if (res.data !== null) {
          setTransactionList((state) => state.concat(res.data.items));
          setTotalTransactionsCount(res.data.total_count);
        } else {
          setLoadErr('No more payments to load');
        }
      })
      .catch((err) => {
        console.log('purchase', err);
        setLoadErr('No more payments to load');
      })
      .finally(() => {
        setIsTransactionsFetched(true);
        setIsTransactionsLoading(false);
      });
  }, []);

  const downloadInvoice = (transaction_id: string) => {
    axios
      .get(endpoints.billings(transaction_id))
      .then((res) => {
        // console.log('download success', res.data);
        window.location.href = res?.data?.invoice_link;
      })
      .catch((err) => {
        console.log('download error', err);
      });
  };

  const handleCreditCardChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSelectedCreditCard((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (isLoading && !isTransactionsFetched) {
      LoadMoreDetails();
    }
    if (isLoading) {
      fetchSavedCards();
    }
  }, []);
  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }
  return (
    <>
      {spaceData.length > 0 && !isLoading && (
        <>
          {subscriptionData[0]?.subscription_type !==
            SUBSCRIPTION_TYPE.PREVIEW && (
            <InstaCard margin="20px">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                height="29px"
                marginBottom="20px"
              >
                <Typography variant="h4">Subscription</Typography>
                <Typography variant="Body1SemiBold">
                  {subscriptionData[0]?.sku_name || 'NA'}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                height="29px"
                margin="20px 0"
              >
                <Typography variant="h4">Next Payment</Typography>
                <Typography variant="Body1SemiBold">
                  {subscriptionData.length > 0
                    ? dayjs(subscriptionData[0]?.next_billing_date).format(
                        'MMM D, YYYY'
                      )
                    : 'NA'}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                height="29px"
                marginTop="20px"
              >
                <Typography variant="h4">Amount</Typography>
                <Typography variant="Body1SemiBold">
                  {subscriptionData.length > 0 &&
                  subscriptionData[0]?.next_bill_amount
                    ? `$${subscriptionData[0]?.next_bill_amount / 100}`
                    : 'NA'}
                </Typography>
              </Stack>
            </InstaCard>
          )}
          {subscriptionData[0]?.subscription_type ===
            SUBSCRIPTION_TYPE.PREVIEW && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height="60px"
              marginBottom="20px"
              sx={{
                borderRadius: '20px',
                background: '#EDEDED',
                position: 'relative',
                padding: '20px 20px',
                boxSizing: 'border-box',
                boxShadow: 'none',
                marginBottom: '20px',
                overflow: 'hidden'
              }}
            >
              <Typography variant="h4">Subscription</Typography>
              <Typography variant="Body1SemiBold">
                {subscriptionData[0]?.sku_name || 'NA'}
              </Typography>
            </Stack>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="16px"
          >
            <Typography variant="h3">PAYMENT METHOD</Typography>
            <Button
              variant="text"
              sx={{ padding: '0px' }}
              onClick={addNewPaymentMethod}
            >
              Add new
            </Button>
          </Stack>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handleCreditCardChange}
          >
            <InstaCard>
              {paymentOption.map(
                (el: {
                  id: string;
                  card: {
                    brand: string;
                    last4: string;
                    exp_month: number;
                    exp_year: number;
                  };
                }) => {
                  const {
                    id,
                    card: { last4, brand, exp_month, exp_year }
                  } = el;
                  return (
                    <FormControlLabel
                      key={id}
                      value={id}
                      control={<Radio />}
                      labelPlacement="start"
                      sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                        margin: (theme) => theme.spacing(2, 0)
                      }}
                      label={
                        <Stack direction="row" alignItems="center" gap={3}>
                          <Image
                            src={brand === 'visa' ? VisaCard : MasterCard}
                            width={brand === 'visa' ? '52' : '40'}
                            height={brand === 'visa' ? '16' : '30'}
                          />
                          <Stack gap={1.5}>
                            <Typography variant="h4">
                              **** **** **** {last4}
                            </Typography>
                            <Typography
                              variant="Body1Medium"
                              color="text.shadedText"
                            >{`Expiry date ${exp_month}/${exp_year}`}</Typography>
                          </Stack>
                        </Stack>
                      }
                    />
                  );
                }
              )}
            </InstaCard>
          </RadioGroup>
          <Typography variant="h3" marginBottom="16px">
            TRANSACTION HISTORY
          </Typography>
          <TableContainer>
            {isTransactionsFetched === true && transactionList.length > 0 && (
              <Table
                sx={{
                  minWidth: 650,
                  border: '2px solid #F0F4F4',
                  borderRadius: '8px'
                }}
              >
                <TableHead sx={{ border: 0 }}>
                  <TableRow>
                    <TableCell align="justify">
                      <Typography variant="h6">Transaction</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">Status</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">Type</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">Amount</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">Transaction Time</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList.map((transaction: TTransaction) => (
                    <TableRow
                      key={transaction.sku_name}
                      sx={{
                        border: 0,
                        '&:nth-child(odd)': { background: '#F0F4F4' }
                      }}
                    >
                      <TableCell
                        sx={{
                          border: 0,
                          fontSize: (theme) =>
                            theme.typography.Body1SemiBold.fontSize,
                          fontWeight: (theme) =>
                            theme.typography.Body1SemiBold.fontWeight,
                          lineHeight: (theme) =>
                            theme.typography.Body1SemiBold.lineHeight
                        }}
                      >
                        {transaction.sku_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: 0
                        }}
                        align="justify"
                      >
                        <Typography variant="Body1SemiBold">
                          {transaction.status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="justify"
                        sx={{
                          border: 0,
                          fontSize: (theme) =>
                            theme.typography.Body1SemiBold.fontSize,
                          fontWeight: (theme) =>
                            theme.typography.Body1SemiBold.fontWeight,
                          lineHeight: (theme) =>
                            theme.typography.Body1SemiBold.lineHeight
                        }}
                        onClick={() => downloadInvoice(transaction.id)}
                      >
                        <Stack
                          borderRadius="8px"
                          maxHeight="28px"
                          justifyContent="center"
                          sx={{ background: '#F8F38B' }}
                        >
                          <Image
                            src={Invoice}
                            height="16"
                            width="16"
                            style={{ marginRight: 10 }}
                          />

                          <Typography variant="Body1SemiBold">
                            {transaction.status === 'Success' ? 'Invoice' : ''}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="justify" sx={{ border: 0 }}>
                        <Typography variant="Body1SemiBold">
                          ${transaction.amount / 100}
                        </Typography>
                      </TableCell>
                      <TableCell align="justify" sx={{ border: 0 }}>
                        <Typography variant="Body1SemiBold">
                          {' '}
                          {dayjs(transaction.transaction_time).format(
                            'YYYY.MM.DD HH:mm:ss'
                          )}{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          {loadErr === '' &&
            isTransactionsFetched === true &&
            totalTransactionsCount > page * pageSize && (
              <Stack
                justifyContent="center"
                onClick={() => setPage((state) => state + 1)}
              >
                <Typography variant="Body1SemiBold">
                  Load More Payments
                </Typography>
                <ExpandMoreIcon />
              </Stack>
            )}
          {loadErr === '' &&
            isTransactionsFetched === true &&
            totalTransactionsCount === 0 && (
              <Stack justifyContent="center" alignItems="center">
                <Typography variant="Body1SemiBold" sx={{ cursor: 'auto' }}>
                  No transactions found
                </Typography>
              </Stack>
            )}
          {loadErr !== '' && (
            <Stack justifyContent="center">
              <Typography variant="Body1SemiBold">{loadErr}</Typography>
            </Stack>
          )}
        </>
      )}

      {spaceData.length === 0 && !isLoading && (
        <InstaCard>
          {[
            <Stack alignItems="center" key="1010">
              <Typography variant="h3">No Space Created</Typography>
            </Stack>
          ]}
        </InstaCard>
      )}
    </>
  );
}

export default billingAndInvoiceSummary;
