import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import InstaCard from 'components/InstaCard';
import { endpoints } from 'services/api/endpoints';
import { TSkipList, TSkipReason } from 'typings';

function SkippingTrial() {
  const navigate = useNavigate();
  const [skipList, setSkipList] = useState<TSkipList[]>([]);
  const [skipReason, setSkipReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const submitReason = () => {
    const payload: TSkipReason = {
      reason_id: Number(skipReason)
    };
    if (Number(skipReason) === 6) {
      payload['other'] = otherReason;
    }

    axios
      .post(endpoints.userSkipTrial, payload)
      .then((res) => {
        console.log('skip free trial', res.data);
      })
      .catch((err) => {
        console.log('skip trial error', err);
      });
  };

  useEffect(() => {
    axios
      .get(endpoints.skipFreeTrial)
      .then((res) => {
        console.log('skip free trial', res.data);
        setSkipList(res.data);
      })
      .catch((err) => {
        console.log('skip trial error', err);
      });
  }, []);

  const handleChange = (e: { target: { value: string } }) => {
    setSkipReason(e.target.value);
    setOtherReason('');
  };

  return (
    <Stack alignItems="center" direction="column" height="100%">
      <Stack>
        <Typography variant="h3" textAlign="center">
          Why are you skipping your FREE Trial?
        </Typography>
        <Typography
          variant="Body1Medium"
          mt={1.5}
          mb={3}
          color="text.shadedText"
          textAlign="center"
        >
          Help us create a better experience
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
        >
          <InstaCard>
            {skipList.map((el) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <FormControlLabel
                  control={<Radio />}
                  label={el.reason}
                  labelPlacement="start"
                  id={el.id}
                  key={el.id}
                  value={el.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '8px'
                  }}
                />
              );
            })}
          </InstaCard>
        </RadioGroup>
        {Number(skipReason) === 6 && (
          <TextField
            multiline
            value={otherReason}
            onChange={(e: { target: { value: string } }) =>
              setOtherReason(e.target.value)
            }
          />
        )}
      </Stack>
      <Stack alignItems="center" width="100%" mt={3}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          style={{
            width: '90%'
          }}
          onClick={() => {
            submitReason();
            navigate('/launchMobileApp');
          }}
          disabled={!skipReason}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}
export default SkippingTrial;
