/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import {
  TSubscriptionType,
  TSubscriptionBuyType,
  TUpgradPayload,
  TTrialResponse
} from 'typings';
import { ApiResponse } from './HttpRequest';
import { endpoints } from './endpoints';

export async function getSubscriptionDetails(): Promise<
  ApiResponse<TSubscriptionType[]>
> {
  const response = await axios.get(endpoints.subscriptionUrl);
  return response;
}

export async function UpgradeUserSubscription(
  payload: TUpgradPayload
): Promise<ApiResponse<TSubscriptionBuyType>> {
  const response = await axios.post(endpoints.subscriptionUpgradeUrl, payload);
  return response;
}

export async function BuyUserSubscription(
  payload: TUpgradPayload
): Promise<ApiResponse<TSubscriptionBuyType>> {
  const response = await axios.post(endpoints.subscriptionBuyUrl, payload);
  return response;
}
export async function checkFreeTrialEligibility(): Promise<
  ApiResponse<TTrialResponse>
> {
  const response = await axios.get(endpoints.checkTrialUrl);
  return response;
}

export async function cancelFreeTrialSubscription(subscriptionId: any) {
  const url = `${endpoints.subscriptionBuyUrl}/${subscriptionId}`;
  const response = await axios.delete(url);
  return response;
}

export const fetchCurrentPlanDetails = async (skuId: string) => {
  const response = await axios.get(endpoints.getCurrentPlanDetails(skuId));
  return response;
};
