import { Stack } from '@mui/material';

export default function LaunchApp() {
  return (
    <Stack alignItems="center" justifyContent="center" height="100vh">
      <h2>
        <a href="/launchApp">Tap here to open the app</a>
      </h2>
    </Stack>
  );
}
