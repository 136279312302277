import React, { useContext, useEffect } from 'react';
import { ConfigContext } from '../../context';

function RedirectToAppStore() {
  const config = useContext(ConfigContext);
  useEffect(() => {
    function checkLinkAndRedirect() {
      const universalLink = `${config.universalLink}/launchApp`; // Universal App Link URL
      const fallbackDelay = 2500; // Wait for 2.5 seconds before falling back to the app store

      function redirectToStore() {
        const { userAgent } = navigator;

        if (/android/i.test(userAgent)) {
          window.location.href =
            'https://play.google.com/store/apps/details?id=ai.instaview.guardian';
        } else {
          window.location.href =
            'https://apps.apple.com/app/instavision-smart-safe-home/id6479250008';
        }
      }

      function openApp(link: string, delay: number) {
        // Set a timeout to redirect to the app store if the app doesn't open
        const timer = setTimeout(() => {
          redirectToStore(); // If app doesn't open, redirect to the store
        }, delay);

        // Try to open the app using the universal link
        window.location.href = link;

        // Clear the timer if the app opens and the user navigates away from the page
        window.addEventListener('blur', () => {
          clearTimeout(timer);
        });
      }

      // First, make a request to check if the Universal Link is working correctly
      fetch(universalLink, { method: 'HEAD' }) // Using HEAD to get the status without loading the entire page
        .then((response) => {
          if (response.ok) {
            // The Universal Link is working, try to open the app
            openApp(universalLink, fallbackDelay);
          } else {
            // The Universal Link is not working, fallback to app store
            redirectToStore();
          }
        })
        .catch(() => {
          // If there was an error (e.g., network issue), fallback to app store
          redirectToStore();
        });
    }

    checkLinkAndRedirect(); // Call the function to check the Universal Link before redirecting
  }, []);

  return (
    <div>
      <p>Redirecting to the appropriate store...</p>
    </div>
  );
}

export default RedirectToAppStore;
