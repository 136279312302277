import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { getPlanDetails } from 'services/api/plansApi';
import {
  SpaceRole,
  TPlanList,
  TSpaceResponseData,
  TSubscriptionType
} from 'typings';
import PlanCycleSwitcher from 'components/PlanCycleSwitcher';
import PlansCarousel from 'components/PlansCarousel';
import InstaSpinner from 'components/InstaSpinner';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';
import InstaCard from 'components/InstaCard';
import { GetAllSpaces } from 'services/api/SpacesApi';

function OurPlans() {
  const [spaceData, setSpaceData] = useState<TSpaceResponseData[]>([]);
  const [spaceDataLoaded, setSpaceDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allPlans, setAllPlans] = useState<TPlanList[]>([]);
  const [userSubscriptions, setUserSubscriptions] = useState<
    TSubscriptionType[]
  >([]);
  const [selectedCyclesPlans, setSelectedCyclesPlans] = useState<TPlanList[]>(
    []
  );

  useEffect(() => {
    GetAllSpaces()
      .then((response) => {
        const ownerSpace = response.data.filter((item) => {
          return item.role === SpaceRole.OWNER;
        });
        setSpaceData(ownerSpace);
        setIsLoading(true);
      })
      .catch(() => {
        setSpaceDataLoaded(false);
      })
      .finally(() => {
        setIsLoading(false);
        setSpaceDataLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (spaceData[0]?.id && !isLoading) {
      // debugger
      setIsLoading(true);

      Promise.all([getPlanDetails(), getSubscriptionDetails()])
        .then((res) => {
          const [allPlansResponse, subscriptionDetails] = res;
          console.log('allPlans ', allPlansResponse);
          console.log('subscriptionDetails ', subscriptionDetails);

          // debugger;
          setAllPlans(allPlansResponse.data);
          setUserSubscriptions(subscriptionDetails.data);
        })
        .catch((err) => {
          // debugger;
          console.log('err', err);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, [spaceData]);

  const filterPlansForCycle = (isYearlyCycle: boolean) => {
    const cycleMonths = isYearlyCycle ? 12 : 1;
    const cyclePlans = allPlans.filter(
      (el: TPlanList) => el.subscription_cycle === cycleMonths
    );
    // debugger
    setSelectedCyclesPlans(cyclePlans);
    setIsLoading(false);
  };

  useEffect(() => {
    if (allPlans.length) {
      filterPlansForCycle(true);
    }
  }, [allPlans]);

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading && selectedCyclesPlans.length) {
    return (
      <Stack pt={2.5}>
        <Typography
          variant="h2"
          sx={{ textTransform: 'capitalize', textAlign: 'center' }}
        >
          Unlock premium features for a more powerful camera!
        </Typography>
        <Typography
          variant="Body1Medium"
          mt={1.5}
          mb={2}
          color="text.shadedText"
          textAlign="center"
        >
          You can choose any of the plans offered and subscribe to be able to
          choose more than one camera
        </Typography>
        {!isLoading &&
          allPlans.length > 0 &&
          selectedCyclesPlans.length > 0 && (
            <>
              <PlanCycleSwitcher
                onChange={(isYearlyCycle: boolean) =>
                  filterPlansForCycle(isYearlyCycle)
                }
              />
              <PlansCarousel
                plansData={selectedCyclesPlans}
                currentPlan={userSubscriptions[0]}
              />
            </>
          )}
      </Stack>
    );
  }

  if (spaceDataLoaded && !isLoading && spaceData.length === 0) {
    return (
      <InstaCard>
        {[
          <Stack alignItems="center" key="1010">
            <Typography variant="h3">No Space Created</Typography>
          </Stack>
        ]}
      </InstaCard>
    );
  }

  return null;
}

export default OurPlans;
