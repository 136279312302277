import styled from 'styled-components';
import { Button, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import BlueCheckboxTick from 'assets/blue-checkbox-tick.svg';
import InstaCard from 'components/InstaCard';
import { useContext, useEffect, useState } from 'react';
import { checkFreeTrialEligibility } from 'services/api/subscriptionApi';
import { ConfigContext } from 'context';

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

function Features() {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isUpgrade, deviceId } = location.state || {};
  const config = useContext(ConfigContext);
  const FreeTrialLogo = config.freeTrialLogo;
  useEffect(() => {
    checkFreeTrialEligibility().then((userFreeTrial) => {
      setIsFreeTrial(userFreeTrial.data.trial);
    });
  }, []);

  return (
    <Stack>
      <Stack>
        <Typography variant="h2" textAlign="center">
          Try our Premium Security Plan
        </Typography>
      </Stack>
      <FreeTrialLogo
        width={isMobile ? `${window.innerWidth - 40}` : '335'}
        style={{ alignSelf: 'center', maxWidth: '100%' }}
        height="300"
      />
      <Typography
        variant="h4"
        sx={{
          textTransform: 'uppercase',
          margin: (theme) => theme.spacing(2, 0)
        }}
      >
        Camera will be equipped with:
      </Typography>
      <InstaCard>
        <Stack
          direction="column"
          justifyContent="space-between"
          marginBottom="20px"
        >
          <Typography variant="h4">AI Detection</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '8px'
            }}
          >
            <Typography
              variant="Body1"
              color="text.shadedText"
              sx={{ maxWidth: '240px' }}
            >
              Detect people, animal and vehicles with your new device! Prevent
              break-ins, track deliveries and protect your belongings with our
              SmartAI.
            </Typography>
            <Image
              src={BlueCheckboxTick}
              width="30px"
              height="30px"
              style={{ alignSelf: 'center', maxWidth: '100%' }}
            />
          </div>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          margin="20px 0px"
        >
          <Typography variant="h4">Event Storage</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '8px'
            }}
          >
            <Typography
              variant="Body1"
              color="text.shadedText"
              sx={{ maxWidth: '240px' }}
            >
              Store your videos securely on the cloud for upto 30 days and keep
              important footages for evidence, or just for the memories!
            </Typography>
            <Image
              src={BlueCheckboxTick}
              width="30px"
              height="30px"
              style={{ alignSelf: 'center', maxWidth: '100%' }}
            />
          </div>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          margin="20px 0px"
        >
          <Typography variant="h4">Contextual AI</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '8px'
            }}
          >
            <Typography
              variant="Body1"
              color="text.shadedText"
              sx={{ maxWidth: '240px' }}
            >
              Receive instant alerts when anything happens in your Home. See
              contextual alerts in the notification to know what’s transpired.
            </Typography>
            <Image
              src={BlueCheckboxTick}
              width="30px"
              height="30px"
              style={{ alignSelf: 'center', maxWidth: '100%' }}
            />
          </div>
        </Stack>
      </InstaCard>
      <Stack pt={4} gap={2} pb={4}>
        {isFreeTrial ? (
          <>
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                textTransform: 'capitalize'
              }}
              onClick={() =>
                navigate('/buySubscription', {
                  replace: true,
                  state: {
                    isFreeTrial,
                    isUpgrade: isUpgrade || false,
                    deviceId
                  }
                })
              }
            >
              Start Free Trial
            </Button>
            <Button
              size="large"
              variant="text"
              color="secondary"
              style={{
                width: '100%',
                textTransform: 'capitalize',
                border: 'none'
              }}
              onClick={() =>
                navigate('/whySkipTrial', {
                  state: {
                    isFreeTrial,
                    deviceId
                  }
                })
              }
            >
              Skip Free Trial
            </Button>
          </>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{
              width: '100%',
              textTransform: 'capitalize'
            }}
            onClick={() =>
              navigate('/buySubscription', {
                replace: true,
                state: {
                  isFreeTrial,
                  isUpgrade: isUpgrade || false,
                  deviceId
                }
              })
            }
          >
            Buy Plan
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
export default Features;
