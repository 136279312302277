/* eslint-disable object-shorthand */
import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAppSelector } from 'store/hooks';
import { getPlanDetails } from 'services/api/plansApi';
import { TPlanList, TPurchasePayload, TSubscriptionType } from 'typings';
import { endpoints } from 'services/api/endpoints';
import PlanCycleSwitcher from 'components/PlanCycleSwitcher';
import PlansCarousel from 'components/PlansCarousel';
import InstaSpinner from 'components/InstaSpinner';
import { getSubscriptionDetails } from 'services/api/subscriptionApi';

// type TProps__BuySubscription = {};

function BuySubscription() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isUpgrade, isFreeTrial } = location.state;

  const spaceData = useAppSelector((state) => state.space.spaceData);
  const [isLoading, setIsLoading] = useState(false);
  const [allPlans, setAllPlans] = useState<TPlanList[]>([]);
  const [userSubscriptions, setUserSubscriptions] = useState<
    TSubscriptionType[]
  >([]);
  const [selectedCyclesPlans, setSelectedCyclesPlans] = useState<TPlanList[]>(
    []
  );

  useEffect(() => {
    if (spaceData[0]?.id && !isLoading) {
      // debugger
      setIsLoading(true);

      Promise.all([getPlanDetails(), getSubscriptionDetails()])
        .then((res) => {
          const [allPlansResponse, subscriptionDetails] = res;
          console.log('allPlans ', allPlansResponse);
          console.log('subscriptionDetails ', subscriptionDetails);

          // debugger;
          setAllPlans(allPlansResponse.data);
          setUserSubscriptions(subscriptionDetails.data);
        })
        .catch((err) => {
          // debugger;
          console.log('err', err);
          setIsLoading(false);
        })
        .finally(() => {});
    }
  }, []);

  const filterPlansForCycle = (isYearlyCycle: boolean) => {
    const cycleMonths = isYearlyCycle ? 12 : 1;
    const cyclePlans = allPlans.filter(
      (el: TPlanList) => el.subscription_cycle === cycleMonths
    );
    // debugger

    setSelectedCyclesPlans(cyclePlans);
    setIsLoading(false);
  };

  useEffect(() => {
    if (allPlans.length) {
      filterPlansForCycle(true);
    }
  }, [allPlans]);

  const createPurchaseId = (plan: TPlanList) => {
    // debugger;
    const payload: TPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: isFreeTrial,
      space_id: spaceData[0]?.id
    };

    // if (subscriptionData.length > 0) {
    //   payload['action'] = 'Upgrade';
    //   payload['previous_purchase_id'] = subscriptionData[0].id;
    // }

    axios
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        navigate('/reviewPurchase', {
          state: {
            isUpgrade: isUpgrade || payload['action'] === 'Upgrade',
            isFreeTrial: isFreeTrial,
            planSelected: plan,
            purchaseId: res.data.purchase_id
          }
        });
      })
      .catch((err) => console.log('purchases err', err));
  };

  const handlePlanSelection = (el: TPlanList) => {
    // debugger;
    // console.log('handlePlanSelection', el);
    // console.log(
    //   'isAnyActiveNonLinkedSubscription ',
    //   isAnyActiveNonLinkedSubscription
    // );

    // if (!pairingFlow && isAnyActiveNonLinkedSubscription && deviceId) {

    // addCameraToSubscription(isAnyActiveNonLinkedSubscription);
    // } else {

    createPurchaseId(el);
    // }
  };

  if (isLoading) {
    return <InstaSpinner withWrapper />;
  }

  if (!isLoading && selectedCyclesPlans.length) {
    return (
      <Stack pt={2.5}>
        {isFreeTrial && !isUpgrade && (
          <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>
            Choose your 15 days Free Trial plan
          </Typography>
        )}
        {isUpgrade && userSubscriptions[0].trial && (
          <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>
            Choose the free trial plan Upgrade
          </Typography>
        )}
        {isUpgrade && !userSubscriptions[0].trial && (
          <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>
            Upgrade the plan that fits your needs
          </Typography>
        )}
        {!isFreeTrial && !isUpgrade && (
          <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>
            Choose the plan that fits your needs
          </Typography>
        )}

        <Typography
          variant="Body1Medium"
          mt={1.5}
          mb={2}
          color="text.shadedText"
        >
          You can choose any of the plans offered and subscribe to be able to
          choose more than one camera
        </Typography>

        {!isLoading &&
          allPlans.length > 0 &&
          selectedCyclesPlans.length > 0 && (
            <>
              <PlanCycleSwitcher onChange={filterPlansForCycle} />
              <PlansCarousel
                plansData={selectedCyclesPlans}
                currentPlan={userSubscriptions[0]}
                allowSelection
                onPlanSelection={handlePlanSelection}
              />
            </>
          )}
      </Stack>
    );
  }
  return null;
}

export default BuySubscription;
