/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack, Typography, styled as styledMUI } from '@mui/material';
import { isMobile } from 'react-device-detect';

import SideBar from 'components/SideBar/SideBar';
import TopBar from 'components/TopBar/TopBar';

const ContentContainer = styledMUI('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '20px'
  },
  [theme.breakpoints.up('sm')]: {
    width: '320px',
    margin: '20px auto 0 auto'
  },
  [theme.breakpoints.up('md')]: {
    width: '440px'
  }
}));

const noSideBarAllowedRoutes = ['/addCameraInfo'];

function PrivateRoute() {
  const location = useLocation();

  console.log('location - ', location);
  console.log(
    'noSideBarAllowedRoutes.includes(location.pathname) - ',
    noSideBarAllowedRoutes.includes(location.pathname)
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isAuthenticated } = useSelector((state: any) => state.user);
  const [openSideBar, setOpenSideBar] = useState(!isMobile);
  if (!isAuthenticated) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }

  // authorized so return child components
  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
        backgroundColor: (theme) => `${theme.palette.background.default}`
      }}
    >
      {openSideBar && (
        <SideBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
      )}
      <Stack
        sx={{
          width:
            openSideBar &&
            !isMobile &&
            !noSideBarAllowedRoutes.includes(location.pathname)
              ? 'calc(100% - 340px)'
              : '100%',
          marginLeft:
            openSideBar &&
            !isMobile &&
            !isMobile &&
            !noSideBarAllowedRoutes.includes(location.pathname)
              ? '340px'
              : 0,
          height: '100%',
          flexDirection: 'column'
        }}
      >
        <TopBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
        <ContentContainer>
          <Outlet />
          <div
            style={{
              textAlign: 'center',
              width: '100%'
            }}
          >
            <Typography variant="Body1">Powered by Instavision</Typography>
          </div>
        </ContentContainer>
      </Stack>
    </Stack>
  );
}

export default PrivateRoute;
