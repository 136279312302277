/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Stack, Typography } from '@mui/material';

import InstaSpinner from 'components/InstaSpinner';

import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'configs/firebaseConfig';
import { setRequestHeaders } from 'utils/commonFunctions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUser } from 'store/reducers/userReducers';
import { clearReduxStates } from 'store/store';
import { endpoints } from 'services/api/endpoints';
import { TStatusMessages, TModule } from 'typings';
import { fetchAllSpaces } from 'store/reducers/spaceReducers';

const statusMessage: TStatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};

function Bridge() {
  const [status, setStatus] = useState<string>('authorizing');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const [customTokenVerified, setCustomTokenVerified] = useState(false);

  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';

  const modulesMapping = {
    dashboard: {
      url: '/dashboard'
    },
    planlist: {
      url: '/subscriptions'
    },
    modifySubscription: {
      url: '/subscription',
      params: 'subscriptionId'
    },
    subscription: {
      url: '/subscriptions'
    },
    authorizeDevice: {
      url: '/authorize_device_from_app',
      authorizationParams: {
        spaceId: 'space_id',
        deviceId: 'device_id'
      }
    }
  };
  const getRedirectionURL = () => {
    const redirectModuleName = query.get('redirect') || '';

    let url = '/';
    if (redirectModuleName) {
      const module: TModule =
        modulesMapping[redirectModuleName as keyof typeof modulesMapping];
      url = module.url;
      console.log('module.url', module);
      if (module?.params) {
        url += `/${query.get(module?.params)}`;
      }
      if (module?.authorizationParams) {
        url += `?space_id=${query.get(
          module?.authorizationParams?.spaceId
        )}&device_id=${query.get(module?.authorizationParams?.deviceId)}`;
      }
    }
    return url;
  };

  const signInWithCustomTokenFunc = async (customToken: string) => {
    try {
      const res = await signInWithCustomToken(auth, customToken);
      const newToken = await res.user.getIdToken();
      setRequestHeaders(newToken);
      dispatch(fetchUser());
      dispatch(fetchAllSpaces());
      setCustomTokenVerified(true);
    } catch (err: any) {
      setStatus('token_expired');
      console.log(err);
    }
  };

  const verifyToken = (one_time_token: string) => {
    axios
      .post(endpoints.verifyToken, {
        one_time_token
      })
      .then((res) => {
        if (res.data.status === 'Valid') {
          signInWithCustomTokenFunc(res.data.custom_token);
        } else {
          setStatus('token_expired');
        }
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('fetchAccessToken', err);
      });
  };

  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    clearReduxStates();
    verifyToken(token);
  }, [token]);

  useEffect(() => {
    if (isAuthenticated && customTokenVerified) {
      const redirectionURL = getRedirectionURL();
      navigate(redirectionURL);
    }
  }, [isAuthenticated, customTokenVerified]);

  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        margin={2.5}
      >
        {status === 'authorizing' && <InstaSpinner withWrapper />}
        <Typography variant="h2">{statusMessage[status]}</Typography>
      </Stack>
    </Stack>
  );
}
export default Bridge;
