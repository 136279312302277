/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetUserDetails } from 'services/api/UserApi';
import { TUserResponseType, TUserState } from 'typings/userTypes';
import { removeRequestHeaders } from 'utils/commonFunctions';

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (payload, thunkAPI) => {
    try {
      const response = await GetUserDetails();
      return response.data;
    } catch {
      return thunkAPI.rejectWithValue('failed');
    }
  }
);

const initialUserState: TUserState = {
  userDetails: null,
  isLoading: 'idle',
  isAuthenticated: false,
  isEligibleForTrial: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    clearUser: (state) => {
      state.userDetails = null;
      state.isLoading = 'idle';
      state.isAuthenticated = false;
      state.isEligibleForTrial = false;
      removeRequestHeaders();
    },
    updateFreeTrialEligibility: (state, action) => {
      state.isEligibleForTrial = action.payload || false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = 'succeeded';
        state.userDetails = action.payload as TUserResponseType;
        state.isAuthenticated = true;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = 'pending';
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = 'failed';
        state.isAuthenticated = false;
      });
  }
});

// Action creators are generated for each case reducer function
export const { clearUser, updateFreeTrialEligibility } = userSlice.actions;

export default userSlice.reducer;
