/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as LoginIllustration } from 'assets/login_illustration.svg';
import { ReactComponent as InstavisionLogo } from 'assets/neuomorph-logo.svg';
import { ReactComponent as InstavisionPaymentSuccessfulIllustration } from 'assets/payment-success-illustration.svg';
import { ReactComponent as InstavisionPaymentDeclinedIllustration } from 'assets/payment-failed-icon.svg';
import { ReactComponent as InstavisionStartFreeTrialIllustration } from 'assets/free-trial-logo.svg';
import { ReactComponent as InstavisionMultiCameraAddedIllustration } from 'assets/multiCameraIcon.svg';
import { ReactComponent as InstavisionCannotAddDeviceIcon } from 'assets/CannotAddSelectedCamera.svg';
import { ReactComponent as InstavisionFreeTrialActivationIllustration } from 'assets/trial-activation-illustration.svg';

// LUNA
import { ReactComponent as LunaAllCamerasAddedToAccount } from 'assets/luna/AllCamerasAddedToAccount.svg';
import { ReactComponent as LunaCannotAddSelectedCamera } from 'assets/luna/CannotAddSelectedCamera.svg';
import { ReactComponent as LunaFreeTrialActivated } from 'assets/luna/FreeTrialActivated.svg';
import { ReactComponent as LunaPaymentDeclined } from 'assets/luna/PaymentDeclined.svg';
import { ReactComponent as LunaPaymentSuccessful } from 'assets/luna/PaymentSuccessful.svg';
import { ReactComponent as LunaStartFreeTrial } from 'assets/luna/StartFreeTrial.svg';
import { ReactComponent as LunaAppLogo } from 'assets/luna/LunaAppLogo.svg';
import { ReactComponent as LunaAppLandingLogo } from 'assets/luna/LunaAppLandingLogo.svg';

import {
  PARTNER_NAME,
  PARTNER_SHORT_NAME,
  PARTNER_UNIVERSAL_LINK
} from './config';

export interface TPartnerConfigurationDetails {
  singinLabel?: string;
  welcomeText?: string;
  loginIllustration?: any;
  logo?: any;
  universalLink?: string;
  paymentSuccessfull?: any;
  paymentFailed?: any;
  freeTrialLogo?: any;
  multiCameraAddedIllustration?: any;
  cannotAddDeviceIcon?: any;
  trialActivationIcon?: any;
  label?: string;
  supportEmail?: string;
  phoneNumber?: string;
}

export interface TPartnerConfiguration {
  [PARTNER_NAME: string]: TPartnerConfigurationDetails;
}

export const partnerConfiguration: TPartnerConfiguration = {
  [PARTNER_NAME.DEFAULT]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Instavision',
    loginIllustration: LoginIllustration,
    logo: InstavisionLogo,
    universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.DEFAULT],
    paymentSuccessfull: InstavisionPaymentSuccessfulIllustration,
    paymentFailed: InstavisionPaymentDeclinedIllustration,
    freeTrialLogo: InstavisionStartFreeTrialIllustration,
    multiCameraAddedIllustration: InstavisionMultiCameraAddedIllustration,
    cannotAddDeviceIcon: InstavisionCannotAddDeviceIcon,
    trialActivationIcon: InstavisionFreeTrialActivationIllustration,
    label: 'InstaVision',
    supportEmail: 'support@instavision.ai',
    phoneNumber: '+1 (877) 916-3025'
  },
  [PARTNER_NAME.LOCAL]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Instavision',
    loginIllustration: LoginIllustration,
    logo: InstavisionLogo,
    universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LOCAL],
    paymentSuccessfull: InstavisionPaymentSuccessfulIllustration,
    paymentFailed: InstavisionPaymentDeclinedIllustration,
    freeTrialLogo: InstavisionStartFreeTrialIllustration,
    multiCameraAddedIllustration: InstavisionMultiCameraAddedIllustration,
    cannotAddDeviceIcon: InstavisionCannotAddDeviceIcon,
    trialActivationIcon: InstavisionFreeTrialActivationIllustration,
    label: 'InstaVision',
    supportEmail: 'support@instavision.ai',
    phoneNumber: '+1 (877) 916-3025'
  },
  [PARTNER_NAME.LUNA_DEV]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Luna AI',
    loginIllustration: LunaAppLandingLogo,
    logo: LunaAppLogo,
    universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LUNA_DEV],
    paymentSuccessfull: LunaPaymentSuccessful,
    paymentFailed: LunaPaymentDeclined,
    freeTrialLogo: LunaStartFreeTrial,
    multiCameraAddedIllustration: LunaAllCamerasAddedToAccount,
    cannotAddDeviceIcon: LunaCannotAddSelectedCamera,
    trialActivationIcon: LunaFreeTrialActivated,
    label: 'Luna AI',
    supportEmail: 'support@lunahomeai.com',
    phoneNumber: '+1 (855) 604-5862'
  },
  [PARTNER_NAME.LUNA_STAGE]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Luna AI',
    loginIllustration: LunaAppLandingLogo,
    logo: LunaAppLogo,
    universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LUNA_STAGE],
    paymentSuccessfull: LunaPaymentSuccessful,
    paymentFailed: LunaPaymentDeclined,
    freeTrialLogo: LunaStartFreeTrial,
    multiCameraAddedIllustration: LunaAllCamerasAddedToAccount,
    cannotAddDeviceIcon: LunaCannotAddSelectedCamera,
    trialActivationIcon: LunaFreeTrialActivated,
    label: 'Luna AI',
    supportEmail: 'support@lunahomeai.com',
    phoneNumber: '+1 (855) 604-5862'
  },
  [PARTNER_NAME.LUNA_PROD]: {
    singinLabel: 'Sign In',
    welcomeText: 'Welcome to Luna AI',
    loginIllustration: LunaAppLandingLogo,
    logo: LunaAppLogo,
    universalLink: PARTNER_UNIVERSAL_LINK[PARTNER_NAME.LUNA_PROD],
    paymentSuccessfull: LunaPaymentSuccessful,
    paymentFailed: LunaPaymentDeclined,
    freeTrialLogo: LunaStartFreeTrial,
    multiCameraAddedIllustration: LunaAllCamerasAddedToAccount,
    cannotAddDeviceIcon: LunaCannotAddSelectedCamera,
    trialActivationIcon: LunaFreeTrialActivated,
    label: 'Luna AI',
    supportEmail: 'support@lunahomeai.com',
    phoneNumber: '+1 (855) 604-5862'
  }
};

type PartnerId = keyof typeof partnerConfiguration;

export const getPartnerConfiguration = (
  partnerId: PartnerId
): TPartnerConfigurationDetails => {
  if (partnerConfiguration[partnerId]) {
    return partnerConfiguration[partnerId];
  }
  return partnerConfiguration[PARTNER_NAME.DEFAULT];
};

export const getPartnerName = (partnerId: PartnerId) => {
  return PARTNER_SHORT_NAME[partnerId];
};
